var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"products-search"},[(_vm.products.length > 0)?[_c('ul',{staticClass:"products-search__list row"},[_vm._l((_vm.products),function(product,index){return _c('div',{key:product.id,staticClass:"col-12 col-md-6"},[_c('li',{staticClass:"products-search__list-item",class:[
              product.responded ? 'products-search__list-item--disabled' : '',
              product.responded && product.found === 'Found'
                ? 'bg-success'
                : product.responded && product.found === 'Not Found'
                ? 'bg-danger'
                : ''
            ],on:{"click":function($event){return _vm.toggleProduct(index)}}},[_c('div',{staticClass:"products-search__image"},[_c('img',{attrs:{"src":product.img,"alt":"product.id"}})]),_c('div',{staticClass:"products-search__details"},[_c('span',[_vm._v(_vm._s(product.amount)+"x "+_vm._s(product.name))]),(_vm.regionCheckUSorOther === true)?_c('span',[_vm._v("$ "+_vm._s(product.unitPrice)+" / unit")]):_c('span',[_vm._v("PKR "+_vm._s(product.unitPrice)+" / unit")])]),_c('div',{staticClass:"products-search__check-box",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.toggleProduct(index)}}},[(product.responded)?[_c('b-form-checkbox',{model:{value:(product.responded),callback:function ($$v) {_vm.$set(product, "responded", $$v)},expression:"product.responded"}})]:[_c('b-form-checkbox',{model:{value:(product.checked),callback:function ($$v) {_vm.$set(product, "checked", $$v)},expression:"product.checked"}})]],2)])])})],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.completed),expression:"!completed"}],staticClass:"products-search__found-not-found"},[_c('button',{staticClass:"baskyt-btn",on:{"click":function($event){return _vm.productsFound(true)}}},[_vm._v("Found")]),_c('button',{staticClass:"baskyt-btn baskyt-btn--white",on:{"click":function($event){return _vm.productsFound(false)}}},[_vm._v(" Not Found ")])])]:_vm._e(),(_vm.loading === true)?[_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle mx-4"}),_c('strong',[_vm._v("Processing...")])],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }